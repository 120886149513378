import React, { useState } from 'react';
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useQuery } from 'react-query';
import CreditTransaction from '../provider/CreditProvider';
import Loading from '../pages/Loading';
import CircleLoading from '../loading/circleLoading';

const creditTransaction = new CreditTransaction();


const Credit = () => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { data, isLoading, isError, refetch } = useQuery(["fetchBooks"],
        creditTransaction.transactionEnCours, { refetchInterval: 1800000 });
    const confirmPurchase = async (creditID) => {
        try {
            setConfirmLoading(true);
            await creditTransaction.confirmAchat(creditID);
            refetch();
            setConfirmLoading(false);
        }
        catch (e) {
            console.error("Error", e);
        }
    }
    const stopPurchase = async (creditID) => {
        try {
            setConfirmLoading(true);
            await creditTransaction.stopPurchase(creditID);
            refetch();
            setConfirmLoading(false);
        }
        catch (e) {
            console.error("Error", e);
        }
    }

    const confirmTransaction = (indicator, creditID) => {
        const confirm = window.confirm(indicator ? "Accepter l'achat?" : "Refuser l'achat?");
        if (confirm) {
            if (indicator) {
                confirmPurchase(creditID);
            } else {
                stopPurchase(creditID);
            }
        }
    }

    if (isError) {
        return (
            <div className="container-fluid">
                <h4>Une erreur s'est produite!</h4>
            </div>
        );
    }
    if (isLoading) {
        return (
            <div className="container d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Loading />
            </div>
        );
    }
    return (
        <div className='container bg-body shadow-sm rounded' style={{ height: "60%" }}>
            <div className="row mt-3">
                <div className="col-3 p-0">
                    <h6 className='text-center' >Name</h6>
                </div>
                <div className="col-2 p-0">
                    <h6 className='text-center'> Category</h6>
                </div>
                <div className="col-3 p-0">
                    <h6 className='text-center'> Reference</h6>
                </div>
                <div className="col-2 p-0">
                    <h6 className='text-center' >Date</h6>
                </div>
            </div>
            <div className="row">
                <ul className="scrollable-list list-unstyled" style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden' }}>

                    {confirmLoading ? <div className="container-fluid d-flex justify-content-center align-items-center"><CircleLoading /></div> :
                        data['data']
                            .filter((user) => user.status === 'pending')
                            .map((user) => (
                                <li key={user.id} className='d-flex flex-row mt-2 border-bottom'>
                                    <div className="col-3">
                                        <p className="text-start">{user.user.name}</p>
                                    </div>
                                    <div className="col-2">
                                        <p className="text-start">{user.credit.type}</p>
                                    </div>
                                    <div className="col-3">
                                        <p className="text-center">{user.reference}</p>
                                    </div>
                                    <div className="col-2">
                                        <p className="text-start">{user.created_at.substring(0, 10)}</p>
                                    </div>
                                    <div className="col-2 d-flex justify-content-around">
                                        <button className="btn btn-success mr-2" onClick={() => confirmTransaction(1, user.id)}><FaCheck /></button>
                                        <button className="btn btn-danger" onClick={() => confirmTransaction(0, user.id)}><MdClose /> </button>
                                    </div>
                                </li>
                            ))}
                </ul>
            </div>
        </div>
    );
};

export default Credit;