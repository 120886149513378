import React from 'react';
import SideBar from './SideBar';
import BookChart from '../components/BookChart';
import PalmaresBookList from '../components/PalmaresBookList';

const Palmares = () => {

    return (
        <div className="container-fluid h-100">
            <div className="row">
                <div className="col-2 p-0">
                    <SideBar />
                </div>
                <div className="col-10">
                    <div className="row">
                        <BookChart />
                    </div>
                    <div className="row mt-1">
                        <PalmaresBookList />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Palmares;