import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';
import userProvider from '../provider/UserProvider';

const ChartUser = () => {
    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    const [initialFetch, setInitialFetch] = useState(true);
    const [isAnnuel, setIsAnnuel] = useState(false);
    const [isAnnuelTemp, setIsAnnuelTemp] = useState(false);
    const [logsKeys, setLogsKeys] = useState([]);
    const [logsData, setLogsData] = useState([]);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(2024);
    const [selectedDate, setSelectedDate] = useState("");
    const { data: logs,  refetch: logRefetch } = useQuery(
        ["fetchLogsData", selectedMonth, selectedYear],
        () => userProvider.getLogsData(selectedMonth, selectedYear),
        {
            enabled: initialFetch,
            onSuccess: (data) => {
                processData(data)
                setInitialFetch(false);
            }
        }
    )
    const [state, setState] = useState({
        series: [
          {
            name: "Oeuvres",
            data: [],
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              formatter: function (val) {
                return Math.round(val);
              }
            }
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      });
    const changeToAnnuel = () => {
        isAnnuel ? setSelectedMonth(currentMonth) : setSelectedMonth(-2);
        setIsAnnuelTemp(!isAnnuelTemp);
    }

    const handleChange = async () => {
      await logRefetch();
      console.log(isAnnuelTemp);
      setIsAnnuel(isAnnuelTemp);
      setSelectedDate(`${months[selectedMonth]}/${selectedYear}`);
    }
    const processData = (logs) => {
      if (logs) {
          setLogsKeys(Object.keys(logs));
          setLogsData(Object.values(logs));
      }
    }
    useEffect(() => {
        if (logs) {
            processData(logs);
        }
    }, [logs]);
    useEffect(() => {
      if (initialFetch) {
            logRefetch();
        }
    }, [initialFetch, logRefetch]);
    
    useEffect(() => {
      setState((prevState) => ({
          ...prevState,
          series: prevState.series.map((serie, index) =>
              index === 0 ? { ...serie, data: logsData } : serie
        ),
        options: {
            ...prevState.options,
            xaxis: {
                ...prevState.xaxis,
                categories: logsKeys.map(key =>isAnnuel ? months[key] + ' / ' + selectedYear : key + ' ' + months[selectedMonth])
            }
        }
      }));
    }, [logsData, logsKeys, isAnnuel]);

    return (
        <div className='container-fluid'>
            <div className='row bg-body' id="chart">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-2 d-flex align-items-center">
                            <p className="fs-5 text-primary text-center" style={{ fontWeight: '600' }}>{isAnnuel ? '' : selectedDate}</p>
                        </div>
                        <div className="col-3 offset-2 d-flex align-items-center p-0">
                            <button className='btn btn-outline-primary' onClick={changeToAnnuel}>{isAnnuelTemp ? 'Annuel' : 'Mensuel'}</button>
                            <div className='border' style={{ width: '1px', height: '30px', margin: '5px' }}></div>
                            <select className="form-select bg-light" defaultValue={months[currentMonth]}
                                onChange={(e) => setSelectedMonth(e.target.selectedIndex)}
                                disabled={isAnnuel}
                            >
                                {months
                                    .map((month, index) => (
                                        <option key={index} value={month} >{month}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <input className="form-control bg-light" type="number" id="number" min="0" max="3000" defaultValue={currentDate.getFullYear()} style={{ borderRadius: '15' }} onChange={(e) => setSelectedYear(e.target.value)} />
                        </div>
                        <div className="col-1 d-flex align-items-center">
                            <button type='button' className='btn btn-primary' onClick={handleChange}>Executer</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ReactApexChart 
                        options={state.options} 
                        series={state.series} 
                        type="bar" 
                        height={350} />
                </div>
            </div>
        </div>
    );
};

export default ChartUser;
