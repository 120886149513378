import React from 'react';
import SideBar from './SideBar';
import Credit from '../components/Credit';
import HistoriqueAchat from '../components/HistoriqueAchat';
// import CreditStatGlogal from '../components/CreditStatGlobal';

const GestionCreditPage = () => {
    return (
        <div className='container-fluid h-100 '>
            <div className="row">
                <div className="col-2 p-0">
                    <SideBar />
                </div>
                <div className="col-10 ">
                    <div className="container-fluid"  style={{height:"100%"}}>
                                <Credit />
                            <HistoriqueAchat />
                        {/* <div className="row">
                        </div>
                        <div className="row">
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GestionCreditPage;