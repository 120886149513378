import React from 'react';
import SideBar from '../pages/SideBar';

const Page404simple = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-2 p-0">
                    <SideBar />
                </div>
                <div className="col-10">
                    <section className="error-container">
                        <span className="four"><span className="screen-reader-text">4</span></span>
                        <span className="zero"><span className="screen-reader-text">0</span></span>
                        <span className="four"><span className="screen-reader-text">4</span></span>
                    </section>
                    <p className="zoom-area"><b>NOT FOUND</b> <br /> We couldn't find what you were looking for. </p>
                </div>
            </div>
        </div>
    );
};

export default Page404simple;