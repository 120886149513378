import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate("/login");
    }
    return (
        <div className=' logout'>
            <button onClick={handleLogout}>Log out</button>
        </div>
    );
};

export default Logout;