const BASE_URL = "https://backendapi.epique.mg";
const token = localStorage.getItem("token");

class BookFilterProvider {
  async getMostLikedAndViewedBooks(month = null, year = null) {
    try {
      let url = `${BASE_URL}/api/most_viewed_liked`;

      if (year && month) {
        url += `/${month + 1}/${year}`;
      } else if (year || month) {
        throw new Error("Both year and month must be provided or none.");
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  }
  async getBookPalmares() {
    try {
      const response = await fetch(`${BASE_URL}/api/book-palmares`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`http error! status: ${response.status}`);
      }
      return response.json();
    } catch (e) {
      console.error("Error: ", e);
    }
  }
  async getBookCreatedAt(month, year) {
    try {
      const response = await fetch(`${BASE_URL}/api/book_created/${month + 1}/${year}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
      if (!response.ok) {
        if (response.status === 500) {
          throw new Error(`error: ${response.error}`);
        }
        throw new Error(`http error! Status: ${response.status}`);
      }
      return response.json();
    }
    catch (e) {
      console.error('Error:');
    }
  }
  async search(keys) {
    try {
      let url = `${BASE_URL}/api/search`;
      url += `/${keys}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Interval error, status: ${response.status}`);
      }
      return response.json();
    } catch (e) {
      throw new Error("Server error: ", e);
    }
  }
  async getAllOnCompetition() {
    try {
      const response = await fetch(`${BASE_URL}/api/competition-books`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("HTTP error status :", response.status);
      }
      return response.json();
    } catch (e) {
      throw new Error("Server Error", e);
    }
  }
  async removeOnCompetition(id) {
    try {
      const response = await fetch(`${BASE_URL}/api/competition-books`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          book_id: id,
        }),
      });
      if (!response.ok) {
        throw new Error("HTTP error status :", response.status);
      }
      return response.json();
    } catch (e) {
      throw new Error("Server Error", e);
    }
  }
  async AddOnCompetition(id) {
    try {
      const response = await fetch(`${BASE_URL}/api/competition-books`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          book_id: id,
        }),
      });
      if (!response.ok) {
        throw new Error("HTTP error status :", response.status);
      }
      return response.json();
    } catch (e) {
      throw new Error("Server Error", e);
    }
  }
}

const ApiProvider = new BookFilterProvider();
export default ApiProvider;
