import React from 'react';
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import CreditTransaction from '../provider/CreditProvider';
import { useQuery } from 'react-query';
import Loading from '../pages/Loading';

const creditTransaction = new CreditTransaction();
const HistoriqueAchat = () => {
    const { data, isLoading, isError } = useQuery(["historyPurchased"], creditTransaction.transactionEnCours, { refetchInterval: 1800000 });
    if (isError) {
        return (
            <div className="container-fluid">
                <h1>Une erreur s'est produite!</h1>
            </div>
        );
    }
    if (isLoading) {
        return (
            <div className="container d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Loading />
            </div>
        );
    }
    return (
        <div className='container-fluid bg-body shadow-sm rounded'>
            <div className="row mt-3">
                <div className="col-4">
                    <h6 className='text-center'>Name</h6>
                </div>
                <div className="col-3">
                    <h6 className='text-center'> Category</h6>
                </div>
                <div className="col-3">
                    <h6 className='text-center'>Date</h6>
                </div>
            </div>
            <div className="row">
                <ul className="scrollable-list list-unstyled" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {data['data']
                        .filter((user) => user.status !== "pending")
                        .map((user) => (
                            <li key={user.id} className='d-flex flex-row bg-light mt-2'>
                                <div className="col-1">
                                    {(user.status === 'approved') ? <p className='text-success'><FaCircleCheck /></p> : <p className='text-danger fs-5' style={{ height: '16px', width: '16px' }}> <IoIosCloseCircle /> </p>}
                                </div>
                                <div className="col-4">
                                    <p className="text-start">{user.user.name}</p>
                                </div>
                                <div className="col-3">
                                    <p className="text-start">{user.credit.type}</p>
                                </div>
                                <div className="col-4">
                                    <p className="text-start">{user.created_at.substring(0, 19)}</p>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export default HistoriqueAchat;