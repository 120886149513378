import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logout from '../components/logout';
import EpiqueLogo from '../assets/image/IMG_20240428_203541.jpg';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const SideBar = () => {
    const [submenuOpen, setSubmenuOpen] = useState(() => {
        const subMenu = localStorage.getItem('subMenu');
        return subMenu ? subMenu : false;
    });
    const toogleSubmenu = () => {
        setSubmenuOpen(!submenuOpen);
    }
    useEffect(() => {
        localStorage.setItem('subMenu', submenuOpen);
    });
    return (
        <div className='container-fluid bg-body shadow-sm rounded' style={{ width: '98%' }} id='navLink'>
            <div className="row d-flex justify-content-center">
                <img src={EpiqueLogo} alt="Logo" style={{ width: '250px' }} />
            </div>
            <div className="row" style={{ height: '80%', marginTop: '50px' }}>
                <ul className='menu'>
                    <NavLink to="/" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li><p className='fs-5 ml-2'>Dasboard</p></li>
                    </NavLink>
                    <NavLink to="/Palmares" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li><p className='fs-5'>Palmares</p></li>
                    </NavLink>
                    <li style={{ width: '100%' }}>
                        <div className='submenu text-center' onClick={toogleSubmenu} style={{ cursor: 'pointer' }}>
                            Services {submenuOpen ? <FaAngleUp /> : <FaAngleDown />}
                        </div>
                        {
                            submenuOpen && (
                                <ul>

                                    <NavLink to="/GestionCredit" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                                        <li><p className='fs-5'>Crédit</p></li>
                                    </NavLink>
                                    <NavLink to="/GestionCompetition" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                                        <li><p className='fs-5'>Concours</p></li>
                                    </NavLink>
                                </ul>
                            )
                        }
                    </li>
                    <NavLink to="/Social" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                        <li><p className='fs-5'>Social</p></li>
                    </NavLink>
                    {/* <NavLink to="/Clubs" className={(nav) => (nav.isActive ? " nav-active" : "")}>
                        <li><p className='fs-5'>Clubs</p></li>
                    </NavLink> */}
                </ul>
            </div>
            <div className="row">
                <Logout />
            </div>
        </div >
    );
};

export default SideBar;