import React, { useState, useEffect } from 'react';
import { FaUser, FaLock } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import CircleLoading from '../loading/circleLoading';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/');
        }
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        const credential = {
            email,
            password
        };
        try {
            setIsLoading(true);
            const response = await fetch('https://backendapi.epique.mg/api/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(credential)
                }).then(response => {
                    if (!response.ok) {
                        if (response.status === 422) {
                            alert('Email or password invalid');
                            setIsLoading(false);
                        }
                        throw new Error(`http error! Status : ${response.status}`);
                        setIsLoading(false);
                    }
                    return response.json();
                })
                .then(data => {
                    const { token } = data;
                    localStorage.setItem('token', token);
                    window.location.replace('/');
                    setIsLoading(false);
                });
        } catch (e) {
            console.error("error :", e);
            setIsLoading(false);
        }
    }

    return (
        <div className="loginpage">
            <div className='login'>
                <form onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <div className="input-box">
                        <input type="email" value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} required />
                        <FaUser className='icon' />
                    </div>
                    <div className="input-box">
                        <input type="password" value={password} placeholder='Password' onChange={(e) => setPassword(e.target.value)} required />
                        <FaLock className='icon' />
                    </div>
                    <button type="submit">{isLoading ? <CircleLoading /> : 'Login'}</button>
                </form>
            </div>
        </div>
    );
};

export default Login;