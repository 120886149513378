import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import userProvider from '../provider/UserProvider';
import { useQuery } from 'react-query';

const Diversite = () => {
    const { data, isError } = useQuery('useDiversity', userProvider.ageDiversity, { refetchOnMount: true });
    const [state] = useState({
        series: [],
        options: {
            labels: ['<17', '18-24', '25-30', '31-40', '41 +'],
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 400
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    });
    return (
        <div className='container-fluid bg-body shadow-sm rounded-3 h-100'>
            {
                isError ? <p className="text-danger fs-3">Une erreur s'est produite !</p> :
                    <div className="row">
                        <ReactApexChart options={state.options} series={data ? data.data : ['100']} type="donut"  />
                        {/* <div id="html-dist"></div> */}
                    </div>
            }
        </div>
    );
};

export default Diversite;