const BASE_URL = "https://backendapi.epique.mg/api";
const token = "";

export default class CreditTransaction {
    async confirmAchat(creditID) {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/credits/${creditID}/confirm`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            if (!response.ok) {
                throw new Error(`HTTP error! status : ${response.status}`);
            }
        }
        catch (e) {
            console.error("ERROR:", e);
        }
    }
    async stopPurchase(creditID) {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/credits/${creditID}/stop`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            if (!response.ok) {
                throw new Error(`HTTP error! status : ${response.status}`);
            }
        }
        catch (e) {
            console.error("ERROR:", e);
        }
    }

    async historiqueTransaction(confirmed) {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${BASE_URL}/purchase-credit/${confirmed}`,
                {
                    methode: 'GET',
                    headers: {
                        'Content-Type': 'Application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

            if (!response.ok) {
                throw new Error(`http error! status: ${response.status}`);
            }
            return null;
        }
        catch (e) {
            throw console.error("ERROR:", e.error);
        }
    }
    async transactionEnCours() {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/purchases-credits`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'applicaton/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            if (!response.ok) {
                throw new Error(`http error! status ${response.status}`);
            }
            return response.json();
        }
        catch (e) {
            throw console.error("error", e.error);
        }
    }
}
