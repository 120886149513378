import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import Login from "./pages/LoginPage";
import Palmares from "./pages/Palmares";
import GestionCreditPage from "./pages/GestionCreditPage";
import Page404simple from './error/Page404simple';
import CompetitionPage from "./pages/CompetitionPage";

function App() {
  return (
    <BrowserRouter>
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/Palmares" element={<Palmares />} />
        <Route path="/GestionCredit" element={<GestionCreditPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/GestionCompetition" element={<CompetitionPage />} />
        <Route path="*" element={<Page404simple />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
